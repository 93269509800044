import { getProductByHandle } from '../api/product';
import { getPetitionPledgeCountByExternalProductID } from '../api/storefront/petition';
import { getProductSalesQuantityByExternalProductId } from '../api/storefront/product';

import { ProductStage, ProductTag, ProductType, EvergreenInventoryState, BadgeText } from '../types/common';
import { getQtyOfProductInCart } from './cart';

export const parseMetafields = (metafieldsRaw: Shopify.Metafield[] | undefined): Metafields =>
  metafieldsRaw
    ? metafieldsRaw.reduce((acc, edge) => (edge ? { ...acc, [edge.key]: edge.value } : { ...acc, undefined }), {})
    : {};

// The order of the tags is important. The first tag that matches will be the stage of the product.
export const getStage = (tags: string[]): ProductStage => {
  if (tags.includes('draft')) {
    return ProductStage.Draft;
  }
  if (tags.includes('petition')) {
    return ProductStage.Petition;
  }
  if (tags.includes('live')) {
    return ProductStage.Live;
  }
  if (tags.includes('comingsoon')) {
    return ProductStage.ComingSoon;
  }
  if (tags.includes('past')) {
    return ProductStage.Past;
  }
  if (tags.includes('demo')) {
    return ProductStage.Demo;
  }
  if (tags.includes('failed')) {
    return ProductStage.Failed;
  }
  if (tags.includes('successful-petition')) {
    return ProductStage.PetitionSuccess;
  }
  if (tags.includes('failed-petition')) {
    return ProductStage.PetitionFailed;
  }
  return ProductStage.Failed;
};

export const getProductType = (productType: string): ProductType => {
  switch (productType) {
    case 'Plushie':
      return ProductType.Plushie;
    case 'Longboi':
      return ProductType.Longboi;
    case 'Charm':
      return ProductType.Charm;
    case 'Sketchbook':
      return ProductType.Sketchbook;
    case 'Keycap':
      return ProductType.Keycap;
    case 'Backpack':
      return ProductType.Backpack;
    case 'Plush Keychain':
      return ProductType.PlushKeychain;
    case 'Keychain Plushie':
      return ProductType.KeychainPlushie;
    case 'Jumbo Plush':
    case 'Jumbo Plushie':
      return ProductType.JumboPlush;
    case 'Squishie':
      return ProductType.SquishyPlush;
    case 'Vinyl Figure':
      return ProductType.VinylFigure;
    case 'Fanny Pack':
      return ProductType.FannyPack;
    case 'Enamel Pin 1 Pack':
      return ProductType.EnamelPin1Pack;
    case 'Enamel Pin 2 Pack':
      return ProductType.EnamelPin2Pack;
    case 'Enamel Pin 3 Pack':
      return ProductType.EnamelPin3Pack;
    case 'Hoodie':
      return ProductType.Hoodie;
    case 'Doughboi':
      return ProductType.Doughboi;
    default:
      return ProductType.Plushie;
  }
};

export const getCustomCampaignTime = (tags: string[]): string | undefined => {
  const customCampaignTag = tags.find((tag) => tag.includes('days-'));
  if (customCampaignTag) {
    const customCampaignTime = customCampaignTag.split('days-')[1];
    return customCampaignTime;
  }
  return undefined;
};

export const hasSale = (product: Shopify.Product): boolean =>
  product.variants.edges.find(({ node }) => node.compareAtPriceV2 !== null) !== undefined;

export const getShopifyQuery = (query: string): string => `(title:${query}*)`;

export const getProductVariantsDetails = (productVariantEdge: Shopify.ProductVariantEdge[]) => {
  const hasManyVariants = productVariantEdge.length > 1;
  const priceArray = productVariantEdge
    .map(({ node }) => ({
      price: node.priceV2.amount,
      compareAtPrice: node.compareAtPriceV2?.amount,
    }))
    .sort((a, b) => a.price - b.price);

  return { hasManyVariants, lowestVariantPrice: priceArray[0] };
};

export const getLiveProductsRemoveCurrentOne = (
  products: Shopify.ProductEdge[],
  currentProduct: Shopify.Product,
): Shopify.ProductEdge[] =>
  products.filter(
    (product) =>
      (product.node.tags.includes(ProductStage.Live) || product.node.tags.includes(ProductStage.Petition)) &&
      !product.node.tags.includes(ProductStage.Draft) &&
      !product.node.tags.includes(ProductTag.Hidden) &&
      product.node.id !== currentProduct.id,
  );

export const filterNonDemoNonDraftProducts = (products: Shopify.ProductEdge[]): Shopify.ProductEdge[] =>
  products.filter((product) => !product.node.tags.includes('demo') && !product.node.tags.includes(ProductStage.Draft));

export const filterTrueMetafieldsCollections = (collections: Shopify.CollectionEdge[]): Shopify.ProductEdge[] => {
  let filteredProduct: Shopify.ProductEdge[] = [];
  for (const collection of collections) {
    if (collection.node.metafield?.value === 'true') {
      filteredProduct = filteredProduct.concat(filterNonDemoNonDraftProducts(collection.node.products.edges));
    }
  }
  return filteredProduct;
};

export const sort360ImageArray = (imageArray: Shopify.Image[]): Shopify.Image[] => {
  const sorted = imageArray.sort(
    (a, b) => a.transformedSrc.split('_360_')[1].split('_')[0] - b.transformedSrc.split('_360_')[1].split('_')[0],
  );
  return sorted;
};

export const getBadgeText = (
  sale: boolean,
  stage: string,
  inventoryState?: EvergreenInventoryState,
): BadgeText | undefined => {
  if (sale && stage !== ProductStage.Past) {
    return BadgeText.Sale;
  }
  if (inventoryState === EvergreenInventoryState.SoldOut) {
    return BadgeText.SoldOut;
  }
  if (inventoryState === EvergreenInventoryState.LowStock) {
    return BadgeText.LowStock;
  }
  return undefined;
};

export const hasProductTag = (tags: string[], searchTag: string): boolean =>
  tags.some((tag) => tag.toLowerCase() === searchTag.toLowerCase());

export const containsProductTag = (tags: string[], searchTag: string): boolean =>
  tags.some((tag) => tag.toLowerCase().includes(searchTag.toLowerCase()));

export const productArrayContainsProductTag = (products: Shopify.ProductEdge[], searchTag: string): boolean =>
  products.some((product) => hasProductTag(product.node.tags, searchTag));

// gidString is shopify product.id string like gid://shopify/Product/1562332
export const getProductIDFromShopifyGID = (gidString: string) => {
  if (gidString.includes('Product/')) {
    const gidSplitArray = gidString.split('Product/');
    if (gidSplitArray.length > 1) return gidSplitArray[1];
  }
  // If productID parsing fails due to unexpected data, return original string so data isn't lost
  return gidString;
};

// gidString is shopify product.id string like gid://shopify/ProductVariant/1562332
export const getProductVariantIDFromShopifyGID = (gidString: string) => {
  if (gidString.includes('ProductVariant/')) {
    const gidSplitArray = gidString.split('ProductVariant/');
    if (gidSplitArray.length > 1) return gidSplitArray[1];
  }
  // If productID parsing fails due to unexpected data, return original string so data isn't lost
  return gidString;
};

// gidString is shopify product.id string like gid://shopify/Checkout/a3928c9d9f93d6f05acb502932f15598?key=4841bc1feac971d8f135e15fd3849e87
export const getCheckoutTokenFromShopifyGID = (gidString: string) => {
  if (gidString.includes('Checkout/')) {
    const gidSplitArray = gidString.split('Checkout/');
    if (gidSplitArray.length > 1) {
      const checkoutTokenArray = gidSplitArray[1].split('?');

      if (gidSplitArray.length > 0) return checkoutTokenArray[0];

      return gidSplitArray[1];
    }
  }
  // If checkoutID parsing fails due to unexpected data, return original string so data isn't lost
  return gidString;
};

// Creates Subscription Tags from campaign stage
export const getCampaignStageTag = (
  stage: string,
  totalInventory: number,
  moq: number,
  isSoldOut?: boolean,
): string => {
  switch (stage) {
    case ProductStage.Failed:
      return `completed-not-funded`;
    case ProductStage.Past:
      return `completed-funded`;
    case ProductStage.Live:
      if (isSoldOut) return `active-sold-out`;
      return totalInventory >= moq ? `active-funded` : `active-not-funded`;
    case ProductStage.ComingSoon:
      return `coming-soon`;
    case ProductStage.Petition:
    case ProductStage.PetitionSuccess:
    case ProductStage.PetitionFailed:
      return `petition`;
    default:
      return '';
  }
};

export const isStagingEnv = () => process.env.NEXT_PUBLIC_DOMAIN && process.env.NEXT_PUBLIC_DOMAIN.includes('staging');

export const calculateIsSoldOut = (
  totalInventory: number,
  checkout: Shopify.Checkout | undefined,
  productTitle: string,
) => {
  let isInventoryLessThanCartQuantity = totalInventory;
  if (checkout) {
    isInventoryLessThanCartQuantity = totalInventory - getQtyOfProductInCart(checkout, productTitle);
  }
  return totalInventory <= 0 || isInventoryLessThanCartQuantity <= 0;
};

export const updateFundedBar = (
  stage: ProductStage,
  product: Shopify.Product,
  totalInventory: number,
  setTotalInventory: (totalInventory: number) => void,
) => {
  const updateInventory = setInterval(
    async () => {
      if (stage !== ProductStage.Live) return;

      const isLimited = hasProductTag(product.tags, ProductTag.Limited);
      const isEvergreen = hasProductTag(product.tags, ProductTag.Evergreen);
      const hasFundedBarOverride = hasProductTag(product.tags, ProductTag.FundedBarOverride);

      let newProduct = product;

      if (isLimited || isEvergreen || hasFundedBarOverride) {
        newProduct = (await Promise.resolve(getProductByHandle(product.handle))) ?? product;
      }

      if (!newProduct) return;

      const newTotalInventory = await calculateTotalInventorySold(newProduct);

      if (newTotalInventory === null) return;

      if (newTotalInventory !== totalInventory) {
        setTotalInventory(newTotalInventory);
      }
    },
    10_000, // Every 10 seconds
    10_000, // Start after 10 seconds upon load
  );

  return () => clearInterval(updateInventory);
  // eslint-disable-next-line react-hooks/exhaustive-deps
};

export const updatePetitionFundedBar = (
  stage: ProductStage,
  product: Shopify.Product,
  totalInventory: number,
  setTotalInventory: (totalInventory: number) => void,
) => {
  const updateInventory = setInterval(
    async () => {
      if (stage !== ProductStage.Petition) return;

      const pledges = await Promise.resolve(
        getPetitionPledgeCountByExternalProductID(getProductIDFromShopifyGID(product.id)),
      )
        .then((response) => response.data)
        .catch(() => totalInventory);

      const newTotalInventory = Math.abs(pledges);

      if (newTotalInventory !== totalInventory) {
        setTotalInventory(newTotalInventory);
      }
    },
    10_000, // Every 10 seconds
    10_000, // Start after 10 seconds upon load
  );

  return () => clearInterval(updateInventory);
  // eslint-disable-next-line react-hooks/exhaustive-deps
};

export const calculateTotalInventorySold = async (product: Shopify.Product) => {
  const isLimited = hasProductTag(product.tags, ProductTag.Limited);
  const isEvergreen = hasProductTag(product.tags, ProductTag.Evergreen);
  const stage = getStage(product.tags);
  const metafields = parseMetafields(product.metafields);

  if (isLimited && (stage === ProductStage.Past || (product.totalInventory ?? 0) <= 0)) {
    return 0;
  }

  if (isLimited || isEvergreen) {
    return product.totalInventory ?? 0;
  }

  if (stage === ProductStage.ComingSoon) {
    return 0;
  }

  if (stage === ProductStage.Past && metafields.totalsold) {
    return metafields.totalsold;
  }

  const hasFundedBarOverride = hasProductTag(product.tags, ProductTag.FundedBarOverride);

  let productSalesQuantity: ProductSalesQuantity | null;

  if (!hasFundedBarOverride) {
    productSalesQuantity = await getProductSalesQuantityByExternalProductId(getProductIDFromShopifyGID(product.id))
      .then((response) => response.data)
      .catch(() => null);
  } else {
    const shopifyProductInventory = Math.abs(product.totalInventory ?? 0);
    productSalesQuantity = {
      quantity: shopifyProductInventory,
    };
  }

  const currentProductSalesQuantity = productSalesQuantity ? productSalesQuantity.quantity : null;

  const totalInventory = currentProductSalesQuantity;

  return totalInventory;
};

export const isProductSoldOut = (product: Shopify.Product, totalInventory: number): boolean =>
  (hasProductTag(product.tags, ProductTag.Limited) || hasProductTag(product.tags, ProductTag.Evergreen)) &&
  totalInventory <= 0;

export const getEvergreenInventoryState = (product: Shopify.Product) => {
  if (!product.tags.includes(ProductTag.Evergreen)) return undefined;
  if (!product.totalInventory || product.totalInventory <= 0) return EvergreenInventoryState.SoldOut;
  if (product.totalInventory <= 50) return EvergreenInventoryState.LowStock;
  return EvergreenInventoryState.InStock;
};

export const shouldShowProgressBar = (stage: ProductStage, isEvergreen?: boolean) =>
  !isEvergreen && stage !== ProductStage.ComingSoon && stage !== ProductStage.Failed;

// Assumes gift card title is formatted as "Gift a X"
export const parseGiftCardProductName = (giftCardTitle: string) => giftCardTitle.split('Gift a ')[1];

export const renderRedirectText = (
  creator: string | undefined,
  productsBySameCreatorToDisplay: Shopify.ProductEdge[],
  productsInSameMakeshiftCollectionToDisplay: Shopify.ProductEdge[],
  makeshiftCollectionTitle: string | null | undefined,
) => {
  if (creator && productsBySameCreatorToDisplay.length === 1) {
    if (productArrayContainsProductTag(productsBySameCreatorToDisplay, ProductStage.Petition)) {
      return `Support ${creator}'s newest petition! 🚀`;
    }
    return `${creator} just dropped a new product. Grab it before it's gone! ✨`;
  }
  if (creator && productsBySameCreatorToDisplay.length > 1) {
    return `Complete your ${creator} Collection! ✨`;
  }
  if (makeshiftCollectionTitle && productsInSameMakeshiftCollectionToDisplay.length === 1) {
    return `${makeshiftCollectionTitle} just dropped a new product. Grab it before it's gone! ✨`;
  }
  if (makeshiftCollectionTitle && productsInSameMakeshiftCollectionToDisplay.length > 1) {
    return `Complete your ${makeshiftCollectionTitle} Collection! ✨`;
  }
  return `Best sellers we think you'll like:`;
};

export const renderLink = (
  productsBySameCreatorToDisplay: Shopify.ProductEdge[],
  productsInSameMakeshiftCollectionToDisplay: Shopify.ProductEdge[],
) => {
  if (productsBySameCreatorToDisplay.length > 0) {
    return `${productsBySameCreatorToDisplay[0].node.handle}`;
  }
  if (productsInSameMakeshiftCollectionToDisplay.length > 0) {
    return `${productsInSameMakeshiftCollectionToDisplay[0].node.handle}`;
  }
  return `/shop/featured`;
};

export const getProductsDisplayed = (
  productsBySameCreator: Shopify.ProductEdge[],
  productsInSameMakeshiftCollection: Shopify.ProductEdge[],
  topCampaigns: Shopify.ProductEdge[],
) => {
  const MAX_PRODUCTS_TO_DISPLAY = 3;
  if (productsBySameCreator.length > 0) {
    return productsBySameCreator.map((product) => getProductIDFromShopifyGID(product.node.id));
  }
  if (productsInSameMakeshiftCollection.length > 0) {
    return productsInSameMakeshiftCollection.map((product) => getProductIDFromShopifyGID(product.node.id));
  }
  return topCampaigns.slice(0, MAX_PRODUCTS_TO_DISPLAY).map((product) => getProductIDFromShopifyGID(product.node.id));
};

export const getFirstThreeUniqueCreators = (topCampaigns: Shopify.ProductEdge[]): Shopify.ProductEdge[] => {
  const uniqueCreators = new Set();
  const uniqueCampaigns: Shopify.ProductEdge[] = [];

  for (const campaign of topCampaigns) {
    const { creator } = parseMetafields(campaign.node.metafields);

    if (!uniqueCreators.has(creator)) {
      uniqueCreators.add(creator);
      uniqueCampaigns.push(campaign);
    }

    if (uniqueCampaigns.length === 3) {
      return uniqueCampaigns;
    }
  }

  return uniqueCampaigns;
};

export const isNotLimitedOrEvergreenProduct = (product: Shopify.Product) =>
  !hasProductTag(product.tags, ProductTag.Limited) && !hasProductTag(product.tags, ProductTag.Evergreen);

export const findCollectionByMetafield = (
  product: Shopify.Product,
  key: string,
  value: string,
): Shopify.CollectionEdge | undefined =>
  product.collections.edges.find((collection) =>
    collection.node.metafields?.some((metafield) => metafield?.key === key && metafield?.value === value),
  );
