import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import { utils } from '@makeship/core';
import { useStore } from '../../store';
import { S2, Caption, P2 } from '../Typography';
import { addProduct } from '../../store/store.actions';
import { getFormattedAmount } from '../../utils/accounting';
import { parseMetafields } from '../../utils/product';
import SpamFlaggingContext from '../../context/spamFlagging';
import { CartEventLocations } from '../../types/common';
import { analyticsTrackAddToCart } from '../../utils/analytics';

const SingleAddOnWrapper = styled.div<{ isVisible: boolean }>`
  display: flex;
  // justify-content: space-between;
  column-gap: 6px;
  position: relative;
  align-items: center;
  cursor: pointer;
  margin: 0 0 16px 0;
  padding: 8px 12px;
  border: ${({ theme }) => `2px solid ${theme.colors.primary}`};
  border-radius: 8px;
  padding-right: 8px;
  background-color: ${({ theme }) => theme.colors.neutral1};

  transition: opacity 0.25s, background-color 0.25s;
  opacity: ${({ isVisible }) => (isVisible ? '100%' : '0%')};

  &:last-child {
    margin-bottom: 0;
  }
  & > ${P2} {
    margin-left: auto;
  }
  &:hover {
    background-color: #f3f2fe;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  height: 100px;
  width: 100px;
  border-radius: 0 8px 8px 8px;
  overflow: hidden;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    height: 80px;
    width: 80px;
    flex-shrink: 0.5;
  }
`;

const PlusImageWrapper = styled.div`
  min-width: 28px;
  min-height: 28px;
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const AddOnTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > ${Caption} {
    color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
  }
`;

const AddOnContentRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

type SingleAddOnProps = {
  product: Shopify.Product;
  setAddOnSuggestions: React.Dispatch<React.SetStateAction<Shopify.Product | undefined>>;
  setIsContainerVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAddOnAccepted: React.Dispatch<React.SetStateAction<boolean>>;
};

const SingleAddOn: React.FC<SingleAddOnProps> = ({
  product,
  setAddOnSuggestions,
  setIsContainerVisible,
  setIsAddOnAccepted,
}: SingleAddOnProps) => {
  const { state, dispatch } = useStore();
  const { incrementSpamCounter, isSpam } = useContext(SpamFlaggingContext);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleAddOnClicked = (product: Shopify.Product) => {
    setIsAddOnAccepted(true);
    setIsContainerVisible(false);
    setAddOnSuggestions(undefined);

    addProduct(state.cart, product, 1, product.variants.edges[0]?.node).then((action) => dispatch(action));

    const metafields = parseMetafields(product.metafields);
    incrementSpamCounter();
    if (!isSpam) {
      analyticsTrackAddToCart(
        product,
        1,
        state.user,
        CartEventLocations.CartAddOn,
        product.variants.edges[0]?.node,
        metafields.campaignID,
      );
    }
  };

  setTimeout(() => {
    setIsVisible(true);
  }, 0);

  return (
    <SingleAddOnWrapper onClick={() => handleAddOnClicked(product)} isVisible={isVisible}>
      <ImageWrapper>
        <Image
          src={product.images.edges[0]?.node.transformedSrc}
          alt={product.images.edges[0]?.node.altText || 'variant image'}
          layout="fill"
          objectFit="contain"
          quality={90}
          unoptimized
        />
      </ImageWrapper>
      <AddOnTextWrapper>
        <AddOnContentRow>
          <S2>{product.title}</S2>
        </AddOnContentRow>
        <AddOnContentRow>
          <P2>${getFormattedAmount(product.variants.edges[0].node.priceV2.amount)}</P2>
        </AddOnContentRow>
      </AddOnTextWrapper>
      <PlusImageWrapper>
        <Image src="/assets/icons/addOn.png" alt="add to cart" width={28} height={28} />
      </PlusImageWrapper>
    </SingleAddOnWrapper>
  );
};

export default SingleAddOn;
