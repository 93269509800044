import React, { createContext, useContext, useState } from 'react';

type ProductDetailsType = {
  moq: number;
  setMOQ: React.Dispatch<React.SetStateAction<number>>;
  totalInventory: number;
  setTotalInventory: React.Dispatch<React.SetStateAction<number>>;
};

export type ProductContextType = {
  product: Shopify.Product;
  productDetails: ProductDetailsType;
};

const ProductContext = createContext<ProductContextType>({} as ProductContextType);

export const getProductFromContext = (): Shopify.Product =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useContext(ProductContext).product;

export const getProductDetailsFromContext = (): ProductDetailsType =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useContext(ProductContext).productDetails;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ProductProvider = ({ value, children }: any) => {
  const [moq, setMOQ] = useState(value.productDetails.moq);
  const [totalInventory, setTotalInventory] = useState(value.productDetails.totalInventory);

  return (
    <ProductContext.Provider
      value={{
        product: value.product,
        productDetails: { moq, setMOQ, totalInventory, setTotalInventory },
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export default ProductProvider;
