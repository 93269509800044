import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { S1 } from '../Typography';
import SingleAddOn from './singleAddOn';

const AddOnsWrapper = styled.div<{ isVisible: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 16px;

  transition: opacity 0.25s;
  opacity: ${({ isVisible }) => (isVisible ? '100%' : '0%')};
`;

const AddOnHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 4px;
`;

const AddOnListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

type AddOnsProps = {
  addOnSuggestion: Shopify.Product | undefined;
  setAddOnSuggestion: React.Dispatch<React.SetStateAction<Shopify.Product | undefined>>;
  setIsAddOnAccepted: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddOns: React.FC<AddOnsProps> = ({ addOnSuggestion, setAddOnSuggestion, setIsAddOnAccepted }: AddOnsProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    if (addOnSuggestion) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [addOnSuggestion]);

  return (
    <AddOnsWrapper isVisible={isVisible}>
      <TopWrapper>
        <AddOnHeader>
          <S1>Add to Your Collection</S1>
        </AddOnHeader>
        <AddOnListWrapper>
          {addOnSuggestion && (
            <SingleAddOn
              product={addOnSuggestion}
              setAddOnSuggestions={setAddOnSuggestion}
              setIsContainerVisible={setIsVisible}
              setIsAddOnAccepted={setIsAddOnAccepted}
            />
          )}
        </AddOnListWrapper>
      </TopWrapper>
    </AddOnsWrapper>
  );
};

export default AddOns;
