import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import CookieConsent from 'react-cookie-consent';

import { Button } from '@makeship/core';
import posthog from 'posthog-js';
import { P2 } from '../Typography';

import config from '../../../config.json';
import { ExperimentVariant, getExperimentFlags } from '../../context/flagging';
import { experimentKeys } from '../../utils/flagging';

const { routes } = config;

const Text = styled(P2)`
  color: ${({ theme }) => theme.colors.neutral1};
`;

const TextABTest = styled(P2)`
  color: ${({ theme }) => theme.colors.neutral1};
  font-size: 14px;
  text-align: center;
`;

const StyledA = styled.a`
  color: ${({ theme }) => theme.colors.neutral1};
  text-decoration: underline;
`;

const StyledLinkABTest = styled.a`
  color: ${({ theme }) => theme.colors.neutral1};
  font-weight: 600;
  transition: font-weight 0.2s ease-in-out;

  &:hover {
    font-weight: 700;
  }
`;

const StyledButton = styled(Button.Tertiary)`
  width: 100%;
`;

const StyledButtonABTest = styled(Button.Tertiary)`
  width: 100%;
  padding: 4px 18px;
  font-size: 14px;
  color: #35465d;

  @media (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    padding: 6px 70px;
  }
`;

const CookieBar: React.FC = () => {
  const isNewCookieConsentBannerEnabled = false; // TODO: Decision needs to be made on cookie consent banner

  return isNewCookieConsentBannerEnabled ? (
    <CookieConsent
      buttonText="GOT IT!"
      containerClasses="consent-wrapper-ab-test"
      buttonWrapperClasses="consent-btn-wrapper-ab-test"
      contentClasses="consent-content-ab-test"
      disableButtonStyles
      ButtonComponent={StyledButtonABTest}
      onAccept={() => {
        posthog.capture('cookie_consent_accepted');
      }}
    >
      <TextABTest>
        <Link href={routes.cookiesPolicy} passHref>
          <StyledLinkABTest>🍪 We use cookies</StyledLinkABTest>
        </Link>{' '}
        to enhance your experience.
      </TextABTest>
    </CookieConsent>
  ) : (
    <CookieConsent
      buttonText="GOT IT!"
      containerClasses="consent-wrapper"
      buttonWrapperClasses="consent-btn-wrapper"
      disableButtonStyles
      ButtonComponent={StyledButton}
      onAccept={() => {
        posthog.capture('cookie_consent_accepted');
      }}
    >
      <Text>
        This website uses cookies to ensure you get the best experience on our website.{' '}
        <Link href={routes.cookiesPolicy} passHref>
          <StyledA>Learn more about our cookies policy.</StyledA>
        </Link>
      </Text>
    </CookieConsent>
  );
};

export default CookieBar;
