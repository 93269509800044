import { createContext, useContext } from 'react';

export type FeatureFlags = {
  [key: string]: boolean;
};

export type ExperimentFlags = {
  [key: string]: ExperimentVariant;
};

export type FlagBagType = {
  featureFlags: FeatureFlags;
  experimentFlags: ExperimentFlags;
};

// Add new experiment variants here and below in experimentVariantMap
export enum ExperimentVariant {
  Control = 'control',
  Variant1 = 'variant1',
  Variant2 = 'variant2',
}

type ExperimentVariantMap = {
  [key: string]: ExperimentVariant;
};

// Add new experiment variants here
export const experimentVariantMap: ExperimentVariantMap = {
  control: ExperimentVariant.Control,
  variant1: ExperimentVariant.Variant1,
  variant2: ExperimentVariant.Variant2,
};

export const emptyFlagBag: FlagBagType = {
  featureFlags: {},
  experimentFlags: {},
};

export const FlaggingContext = createContext<FlagBagType>(emptyFlagBag);

export const getFeatureFlags = (): FeatureFlags => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const flagBag = useContext(FlaggingContext);
  return flagBag.featureFlags || {};
};

export const getExperimentFlags = (): ExperimentFlags => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const flagBag = useContext(FlaggingContext);

  if (process.env.E2E_TEST_ENVIRONMENT === 'true') {
    return {};
  }
  return flagBag.experimentFlags || {};
};

// Returns overridden experiment flags, where all other experiments are set to control
// The given flagKey is set to the desired experiment variant
export const createOverrideExperimentFlags = (experimentFlags: ExperimentFlags, flagKey: string): ExperimentFlags => {
  const newExperimentFlags: ExperimentFlags = {};
  for (const experimentFlag in experimentFlags) {
    if (experimentFlag !== flagKey) {
      newExperimentFlags[experimentFlag] = ExperimentVariant.Control;
    } else {
      newExperimentFlags[experimentFlag] = experimentFlags[flagKey];
    }
  }
  return newExperimentFlags;
};
