import React, { ReactNode, useContext, useEffect, useReducer, useState } from 'react';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { Action, updateCheckout, updateUser, logout, addCustomer, updateCartWithDiscountCode } from './store.actions';
import { storeReducer } from './store.reducer';
import { getCustomer } from '../api/customer';
import { getCustomer as getStorefrontCustomer } from '../api/storefront/customer';
import { createCheckout, getCheckout } from '../api/checkout';

import config from '../../config.json';
import { accessTokenKey, usernameKey, checkoutIdKey } from '../utils/cookies';

const { routes } = config;

export type State = {
  cart: Cart;
  user?: User;
};

const accessToken = Cookies.get(accessTokenKey);
const username = Cookies.get(usernameKey);

const initialState: State = {
  cart: {
    open: false,
    checkout: undefined,
  },
  user: {
    accessToken,
    customer: undefined,
    storefrontCustomer: {
      username,
    },
  },
};

type Store = {
  state: State;
  dispatch: React.Dispatch<Action>;
};

const store = React.createContext<Store>({
  state: initialState,
  dispatch: () => null,
});

export const useStore = (): Store => useContext(store);

const { Provider } = store;

type AppContextProps = {
  children: ReactNode;
};

export const AppContext: React.FC<AppContextProps> = ({ children }: AppContextProps) => {
  const [state, dispatch] = useReducer(storeReducer, initialState);
  const router = useRouter();
  const [hasURLDiscountCode, setHasURLDiscountCode] = useState(false);

  const _createCheckout = () =>
    createCheckout()
      .then((data) => data?.checkout)
      .then((checkout) => {
        if (checkout) {
          dispatch(updateCheckout(checkout));
          Cookies.set(checkoutIdKey, checkout.id, { expires: 90, secure: true });
        }
      });

  useEffect(() => {
    const accessToken = Cookies.get(accessTokenKey);
    const checkoutId = Cookies.get(checkoutIdKey);
    if (checkoutId) {
      getCheckout(checkoutId)
        .then((checkout) => {
          if (!checkout || checkout.completedAt) {
            // Didn't get a checkout or checkout has been completed
            _createCheckout();
          } else {
            // Got a checkout
            dispatch(updateCheckout(checkout));
          }
        })

        // Errored on getting checkout
        .catch(() => _createCheckout);
    } else {
      _createCheckout();
    }

    if (accessToken) {
      getCustomer(accessToken).then(async (customer) => {
        if (customer) {
          try {
            const storefrontCustomer = await getStorefrontCustomer(accessToken).then((res) => res.data);
            dispatch(updateUser(accessToken, customer, storefrontCustomer));
            dispatch(addCustomer(storefrontCustomer));
            if (storefrontCustomer.email) {
              const _learnq = (window as any)._learnq || [];
              _learnq.push([
                'identify',
                {
                  $email: storefrontCustomer.email,
                },
              ]);
            }
          } catch (err) {
            dispatch(logout());
            router.push(routes.login);
          }
        } else {
          dispatch(logout());
          router.push(routes.login);
        }
      });
    } else {
      Cookies.remove(usernameKey);
    }
  }, []);

  useEffect(() => {
    if (!router.isReady || !state.cart.checkout || hasURLDiscountCode) return;

    // ?ac=DISCOUNT_CODE
    if (router.query.ac && state.cart.checkout) {
      setHasURLDiscountCode(true);
      updateCartWithDiscountCode(router.query.ac as string, state.cart);
    }
  }, [router.isReady, state.cart.checkout]);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};
